<template>
  <div id="personaltwo">
    <div class="title">
      <h1>選擇Finger，得到優勢。</h1>
      <div class="content">
        <div class="boxBox">
          <div class="box">
            <h1>+0</h1>
            <h2 class="yellow">傭金</h2>
          </div>
          <div class="box">
            <svg-icon name='earth' color="#fff" size="80"/>
            <h2>全球交易</h2>
          </div>
          <div class="box">
            <svg-icon name='support' color="#fff" size="80"/>
            <h2 class="yellow">私人管家</h2>
          </div>
        </div>

      </div>
      <div class="btnbox">
        <btn-border @click="goMore">了解更多</btn-border>
      </div>
    </div>
  </div>
</template>

<script>
import BtnBorder from '@/components/button/BtnBorder.vue'
import SvgIcon from '@/components/SvgIcon/SvgIcon.vue'
export default {
  name:'PersonalTwo',
  components: {
    BtnBorder,
    SvgIcon
  },
  data() {
    return {

    }
  },
  methods:{
    goMore(){
      this.$router.push('/service/personal3')
    }
  },
}
</script>

<style scoped>
.title{
  padding-top: 200px;
  color: aliceblue;
  text-align: center;
  margin-bottom: 40px;
}

.title h1{
  font-size: 60px;
}
.btnbox{
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}
.content{
  width: 100%;
  display: flex;
  justify-content: center;
}
.boxBox{
  width: 40%;
  display: flex;
  justify-content: space-between;
  margin-top: 80px;
  margin-bottom: 120px;
}
h2{
  margin-top: 20px;
  font-size: 40px;
}
.yellow{
  color: yellow;
}
</style>
